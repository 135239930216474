<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h3 class="">Gerenciamento de indicações</h3>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="float-right d-none d-md-block">
          <button
            class="btn btn-success"
            @click.prevent="$refs.tableReferrals.refresh()"
          >
            <i class="mdi mdi-refresh"></i>
            Atualizar resultados
          </button>
        </div>
      </div>
    </div>
    <div class="card mb-4">
      <div class="card-body">
        <div class="row mb-md-2">
          <div
            v-if="
              ['admin', 'reviewer', 'content-manager'].includes(
                currentUser.role
              )
            "
            class="col-sm-12 col-md-6"
          >
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="dataTables_filter">
              <div class="text-md-right">
                <label class="d-inline-flex align-items-center">
                  Pesquisar nome do favorecido:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Pesquisar..."
                    class="form-control ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="table-responsive mb-0">
          <b-table
            ref="tableReferrals"
            :items="referralProvider"
            :fields="fields"
            responsive
            flex
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(partial)="data">
              {{
                data.item.question.length > 100
                  ? `${$options
                      .stripHtml(data.item.question)
                      .result.slice(0, 100)}...`
                  : $options.stripHtml(data.item.question).result
              }}
            </template>

            <template v-slot:cell(actions)="data">
              <button
                v-if="['admin'].includes(currentUser.role)"
                @click="paymentreferral(data.item)"
                class="btn btn-warning mr-2"
              >
                Pagar
              </button>
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import stripHtml from "string-strip-html";
import Layout from "../layouts/main";
import appConfig from "@/app.config";

import { mapState } from "vuex";

import * as api from "@/api";
import Swal from "sweetalert2";

export default {
  stripHtml,

  name: "ManageReferral",

  page: {
    title: "Gerenciamento de indicações",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    Layout
  },

  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "amount",
      sortDesc: true,
      reviewNeededOnly: false,
      errorsReportedOnly: false,
      fields: [
        { key: "user.username", label: "Usuário convidado", sortable: true },
        { key: "referer.username", label: "Usuário que indicou", sortable: true },
        { key: "amount", label: "Valor", sortable: true },
        { key: "ableToRewardIn", label: "Data para Resgate", sortable: true },
        { key: "actions", label: "Opções", sortable: false },
      ],
      infoModal: {
        id: "info-modal",
        title: "",
        content: null,
      },
    };
  },

  computed: {
    ...mapState("auth", ["currentUser"]),
  },

  methods: {
    referralProvider(ctx) {
      const params = {
        page: ctx.currentPage,
        limit: ctx.perPage,
        search: ctx.filter ? ctx.filter : undefined,
        order: ctx.sortBy ? ctx.sortBy : undefined,
        direction: ctx.sortDesc ? "desc" : "asc"
      };
      return api
        .getReferrals(params)
        .then((pagination) => {
          this.totalRows = pagination.total;
          return pagination.items;
        })
        .catch(() => {
          this.$swal("Opa", "Falha ao carregar indicações de usuários!", "error");
          return [];
        });
    },
    paymentreferral(referral) {
      this.$swal({
        title: "Você tem certeza?",
        text: "Esta ação não pode ser revertida!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#02a499",
        cancelButtonColor: "#ec4561",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, pagar!",
      }).then((result) => {
        if (result.value) {
          return this.doPayment(referral);
        }
      });
    },
    doPayment(referral) {
      if (referral.id) {
        Swal.fire({
          icon: "success",
          title: "Referral pago com sucesso!",
        });
      }

      api
        .updateReferral(referral.id)
        .then(() => {
          this.$refs.tableReferrals.refresh();
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Referral pago com sucesso!",
          });
        })
        .catch((err) => {
          if (err) {
            this.$swal("Opa", "Falha ao realizar pagamento", "error");
          } else {
            this.$swal.stopLoading();
            this.$swal.close();
          }
        });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  },
};
</script>
